import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { generateShortUuid } from 'helpers/generate/uuid'
import { getLatitudeLongitude } from 'helpers/navigator'
import { getSessionStorage } from 'helpers/session-storage'
import { setCanonicalUrl } from 'helpers/url'

interface ICartPayload {
  product_uid: string | string[]
  qty?: number
  idempotentKey: string
}
//deprecated on event
export const getCartValues = async (payload: ICartPayload) => {
  const response = await apiClient({
    url: '/order/v2/cart',
    method: 'POST',
    token: getIdToken(),
    data: {
      products: [
        { product_uid: payload.product_uid, qty: payload.qty || 1 },
      ],
    },
    headers: { 'Idempotent-Key': payload.idempotentKey },
  })
  return response.data.data
}

interface ICheckoutPayload {
  invoice_id: string
  token: string
  product_id: string
  accesstrade_click_id: string
  voucher_code?: string
  promoter_token: string
  user_mask_id?: string
  additional_info?: any
  payment_method?: string
  idempotentKey: string
  selected_seats?: string[]
  send_to_additional_emails?: boolean
}

export const getCheckoutValues = async (
  payload: ICheckoutPayload,
) => {
  const response = await apiClient({
    url: '/order/v1/checkout',
    method: 'POST',
    token: getIdToken(),
    data: {
      invoice_id: payload.invoice_id,
      token: payload.token,
      product_id: payload.product_id,
      accesstrade_click_id: payload.accesstrade_click_id,
      voucher_code: payload.voucher_code,
      promoter_token: payload.promoter_token,
      user_mask_id: payload.user_mask_id,
      additional_info: payload.additional_info,
      selected_seats: payload.selected_seats,
      payment_method: payload.payment_method,
      send_to_additional_emails:
        payload.send_to_additional_emails || false,
    },
    headers: { 'Idempotent-Key': payload.idempotentKey },
  })
  return response.data.data
}

interface ICheckoutInstruction {
  invoice_id: string
  payment_method: string
  idempotentKey: string
}
export const getCheckoutInstruction = async (
  payload: ICheckoutInstruction,
) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/va/detail`
      : `/payment/guest/v1/directpay/va/detail`,
    method: 'GET',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      invoice_id: payload.invoice_id,
      payment_method_code: payload.payment_method,
    },
  })

  return response.data.data
}
interface IPaymentHistoryPayload {
  payment_status?: string
  product_type?: number
  cursor?: string
}

export const getPaymentHistory = async (
  payload: IPaymentHistoryPayload,
) => {
  //request from BE for cleaner on BE side
  const paramHandler = () => {
    if (payload.payment_status !== '' && payload.product_type !== 0) {
      return {
        payment_status: payload.payment_status,
        product_type: payload.product_type,
        cursor: payload.cursor,
      }
    } else if (
      payload.payment_status === '' &&
      payload.product_type !== 0
    ) {
      return {
        product_type: payload.product_type,
        cursor: payload.cursor,
      }
    } else if (
      payload.payment_status !== '' &&
      payload.product_type === 0
    ) {
      return {
        payment_status: payload.payment_status,
        cursor: payload.cursor,
      }
    } else {
      return {
        cursor: payload.cursor,
      }
    }
  }
  const response = await apiClient({
    url: `/payment/v1/directpay/history`,
    method: 'GET',
    token: getIdToken(),
    params: paramHandler(),
  })
  return response.data
}

interface IVaStatus {
  invoice_id: string
  payment_method: string
  idempotentKey: string
}
export const getPaymentStatus = async (payload: IVaStatus) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/va/check-status`
      : `/payment/guest/v1/directpay/va/check-status`,
    method: 'GET',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      invoice_id: payload.invoice_id,
      payment_method_code: payload.payment_method,
    },
  })
  return response.data.data
}

interface IVaConfirm {
  invoice: string
}
export const paymentConfirmation = async (payload: IVaConfirm) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/test/v1/confirm`
      : `/payment/guest/test/v1/confirm`,
    method: 'POST',
    token: getIdToken(),
    headers: { 'Idempotent-Key': generateShortUuid() },
    data: {
      invoice: payload.invoice,
      req_type: 'payment',
    },
  })

  return response.data.data
}
export interface IProductsProps {
  product_uid: string | string[]
  qty?: number
  head_source: string
  promoter_token?: string
  additional_info?: Array<{
    full_name: string
    email: string
    phone_number: string
  }>
  selected_seats?: Array<string>
  send_to_additional_emails?: boolean
  tickets?: Array<{
    ticket_schedule_uid: string
    qty: number
  }>
}
export interface IPaymentCheckoutBody {
  products?: Array<IProductsProps>
  payment_method: string
  checkout_source: string
  user_mask_id?: string
  voucher_code?: string
  idempotentKey: string
}

export const checkoutPayment = async ({
  products,
  payment_method,
  checkout_source,
  user_mask_id,
  voucher_code,
  idempotentKey,
}: IPaymentCheckoutBody) => {
  const location = await getLatitudeLongitude()
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/checkout`
      : `/order/guest/v1/checkout`,
    method: 'POST',
    token: getIdToken(),
    data: {
      products: products,
      payment_method: payment_method,
      checkout_source: checkout_source,
      user_mask_id: user_mask_id,
      voucher_code: voucher_code,
      redirection_link:
        getIdToken() &&
        (checkout_source.toLocaleLowerCase() === 'cart'
          ? `${window.location.origin}/transaction-history`
          : setCanonicalUrl()),
    },
    headers: {
      'Idempotent-Key': idempotentKey,
      Location:
        `${location['latitude']}, ${location['longitude']}` ||
        `${getSessionStorage('metaData')['latitude']},${
          getSessionStorage('metaData')['longitude']
        }` ||
        '',
      'Device-Real': false,
      'Device-Rooted': false,
    },
  })
  return response.data.data
}

interface IPreCheckoutBody {
  products?: Array<IProductsProps>
  checkout_source: string
  user_mask_id?: string
  idempotentKey: string
}

export const preCheckoutPayment = async ({
  products,
  checkout_source,
  idempotentKey,
}: IPreCheckoutBody) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/pre-checkout`
      : `/order/guest/v2/pre-checkout`,
    method: 'POST',
    token: getIdToken(),
    data: { products: products, checkout_source: checkout_source },
    headers: { 'Idempotent-Key': idempotentKey },
  })
  return response.data.data
}
interface ICancelPayment {
  invoice_id: string
  idempotentKey?: string
}
export const cancelPayment = async (payload: ICancelPayment) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/cancel`
      : `/payment/guest/v1/directpay/cancel`,
    method: 'POST',
    token: getIdToken(),
    data: { invoice_id: payload?.invoice_id },
    headers: {
      'Idempotent-Key': payload.idempotentKey || generateShortUuid(),
    },
  })
  return response.data.message
}

export const topUpCoinPayment = async ({ coins, idempotentKey }) => {
  try {
    const response = await apiClient({
      url: `/payment/v1/topup`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': idempotentKey },
      data: {
        coins: coins,
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

interface IPostTopupRequest {
  idempotentKey: string
  payment_id: string
  payment_method_code: string
}
export const postTopupRequest = async ({
  idempotentKey,
  payment_id,
  payment_method_code,
}: IPostTopupRequest) => {
  try {
    const response = await apiClient({
      url: `/payment/v1/topup/invoice`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': idempotentKey },
      data: {
        payment_id: payment_id,
        payment_method_code: payment_method_code,
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}
