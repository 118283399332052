import { styled } from '@mui/material'
import Box from 'components/commons/Box'
import Button from 'components/commons/Button'
import Typography from 'components/commons/Typography'
type urlBg = {
  urlBg?: string
}
export const DescWrapper = styled(Box)({
  height: '115px',
  display: 'flex',
  alignItems: 'center',
})

export const ImageWrapper = styled(Box)<urlBg>(
  ({ theme, urlBg }) => ({
    backgroundImage: `url(${urlBg})`,
    top: 0,
    maxWidth: '372px',
    height: '175px',
    backgroundSize: 'cover',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    color: theme.palette.text.primary,
    margin: 'auto',
    filter: 'drop-shadow(0 3px 5px rgba(0, 0, 0, 0.2))',
  }),
)
export const VoucherContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  margin: 'auto',
}))

export const VoucherName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.h3.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '22px',
  letterSpacing: '0.0015em',
  justifyContent: 'center',
  alignContent: 'center',
}))

export const VoucherHeader = styled(Box)({
  paddingTop: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  height: '30px',
  alignItems: 'center',
  svg: {
    transform: 'scale(0.7)',
    marginRight: '-37px',
  },
})
export const VoucherDesc = styled(Box)(({ theme }) => ({
  clear: 'both',
  display: 'flex',
  letterSpacing: '0.0025em',
  lineHeight: '16px',
  fontWeight: theme.typography.smallRegular.fontWeight,
  fontSize: theme.typography.smallRegular.fontSize,
  marginBottom: '5px',
  svg: {
    fontSize: 16,
  },
}))
export const VoucherDescText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.smallRegular.fontWeight,
  fontSize: '12px',
  lineHeight: '16px',
}))

export const ButtonWrapper = styled(Box)({
  position: 'absolute',
  bottom: 12,
})
export const VoucherButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.gradient1,
  padding: '12px 8px',
  width: 'calc(342px - 17px)',
  height: '32px',
  borderRadius: '12px',
  margin: 'auto',
}))

export const VoucherCardWrapper = styled(Box)({
  backgroundColor: '#fff0',
  cursor: 'pointer',
  marginBottom: 16,
  width: '100%',
  height: '100%',
  position: 'relative',
})

export const VoucherContentWrapper = styled(Box)({
  display: 'block',
  margin: 'auto',
  paddingLeft: '27px',
  paddingRight: '27px',
})
