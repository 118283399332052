import isEmpty from 'helpers/is-empty'

function getURLExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

type ImageSize = '100w' | '200w' | '300w' | '500w' | '700w' | '1200w'
const DEFAULT_IMAGE_SIZE: ImageSize = '500w'
const ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png']
const IMAGE_RESIZE_ENABLED = Boolean(
  process.env.NEXT_PUBLIC_IMAGE_RESIZE,
)

export default function imageResizer(
  imageUrl: string,
  imageSize: ImageSize = DEFAULT_IMAGE_SIZE,
) {
  if (!IMAGE_RESIZE_ENABLED) return imageUrl
  if (isEmpty(imageUrl)) return imageUrl
  if (!ALLOWED_IMAGE_EXTENSIONS.includes(getURLExtension(imageUrl)))
    return imageUrl

  const newImageUrl = imageUrl.split('/')

  newImageUrl.splice(newImageUrl.length - 1, 0, imageSize)
  return newImageUrl.join('/')
}
