import { SxProps, styled } from '@mui/material/styles'
import appConfig from 'configs/app'
import { useState } from 'react'

const StyledImage = styled('img')({
  objectFit: 'contain',
  objectPosition: 'center',
  backgroundRepeat: 'no-repeat',
})

type ImageType =
  | 'error'
  | 'create-creator-success'
  | 'creator-onboarding'
  | 'external-link'
  | 'followed-creator'
  | 'pending-withdraw'
  | 'success-withdraw'
  | 'complete-withdraw'
  | 'no-session-created-yet'
  | 'page-not-found'
  | 'system-error'
  | 'user-alert'
  | 'ticket-not-available'
  | 'empty-data'
  | 'date-time'
  | 'share-content'
  | 'empty-search'
  | 'session-almost-full'
  | 'access-permission'
  | 'no-respond'
  | 'general-failed-load'
  | 'calendar'
  | 'guest_pending'
  | 'unhappy_booking'

interface StateImageProps {
  src?: string
  sx?: SxProps
  alt?: string
  type?: ImageType
}

const StateImage = ({
  src = '/img/webp/error.webp',
  sx,
  type,
  alt,
}: StateImageProps) => {
  const [isError, setisError] = useState(false)

  const sourceMapping = () => {
    switch (type) {
      case 'error':
        return '/img/webp/error.webp'
      case 'create-creator-success':
        return `${appConfig.assetBaseUrl}/webp/state_become_creator.webp`
      case 'creator-onboarding':
        return `${appConfig.assetBaseUrl}/webp/state_user_circle.webp`
      case 'external-link':
        return `${appConfig.assetBaseUrl}/webp/state_external_link.webp`
      case 'followed-creator':
        return `${appConfig.assetBaseUrl}/webp/state_user_card.webp`
      case 'pending-withdraw':
        return `${appConfig.assetBaseUrl}/webp/state_waiting_money.webp`
      case 'success-withdraw':
        return `${appConfig.assetBaseUrl}/webp/state_success_get_money.webp`
      case 'complete-withdraw':
        return `${appConfig.assetBaseUrl}/webp/state_complete_withdraw.webp`
      case 'no-session-created-yet':
        return `${appConfig.assetBaseUrl}/webp/state_laptop_table.webp`
      case 'page-not-found':
        return `${appConfig.assetBaseUrl}/webp/page_not_found.webp`
      case 'system-error':
        return `${appConfig.assetBaseUrl}/webp/state_system_error.webp`
      case 'user-alert':
        return `${appConfig.assetBaseUrl}/webp/state_user_alert.webp`
      case 'ticket-not-available':
        return `${appConfig.assetBaseUrl}/webp/state_ticket_not_available.webp`
      case 'empty-data':
        return `${appConfig.assetBaseUrl}/webp/empty_data_state.webp`
      case 'date-time':
        return `${appConfig.assetBaseUrl}/webp/state_date_time.webp`
      case 'share-content':
        return `${appConfig.assetBaseUrl}/webp/state_share_content.webp`
      case 'empty-search':
        return `${appConfig.assetBaseUrl}/webp/empty_search.webp`
      case 'session-almost-full':
        return `${appConfig.assetBaseUrl}/webp/session_almost_full.webp`
      case 'access-permission':
        return `${appConfig.assetBaseUrl}/webp/state_acess_permission.webp`
      case 'no-respond':
        return `${appConfig.assetBaseUrl}/webp/state_no_respond.webp`
      case 'general-failed-load':
        return `${appConfig.assetBaseUrl}/webp/state_general_failed_load.webp`
      case 'calendar':
        return `${appConfig.assetBaseUrl}/webp/state_calendar.webp`
      case 'guest_pending':
        return `${appConfig.assetBaseUrl}/waiting_money.svg`
      case 'unhappy_booking':
        return `${appConfig.assetBaseUrl}/unhappy_booking.svg`
      default:
        return src
    }
  }

  if (isError) {
    return (
      <StyledImage
        src={`${appConfig.assetBaseUrl}/webp/error.webp`}
        alt={'Error image'}
        sx={{ width: '240px', height: '180px', ...sx }}
      />
    )
  }

  return (
    <StyledImage
      src={sourceMapping()}
      alt={alt || type}
      sx={{ width: '240px', height: '180px', ...sx }}
      onError={() => setisError(true)}
    />
  )
}

export default StateImage
