import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { useTranslation } from 'react-i18next'

export default function VoucherErrorContent() {
  const { t } = useTranslation()
  return (
    <Box>
      <StateImage
        type="error"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <ErrorLabel>{t('key_failed_use_voucher')}</ErrorLabel>
      <ErrorDesc>{t('key_failed_use_voucher_desc')}</ErrorDesc>
    </Box>
  )
}
