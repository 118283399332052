import { styled } from '@mui/material'
import Button from '../Button'
import Box from '../Box'
import Typography from '../Typography'

export function FieldTitle({
  content,
  isRequired = false,
}: {
  content: string
  isRequired?: boolean
}) {
  if (!content) {
    return null
  }

  return (
    <Typography
      variant="normalBold"
      sx={(theme) => ({
        color: theme.palette.text.primary,
        whiteSpace: 'break-spaces',
      })}
    >
      {content}
      {isRequired && (
        <Typography
          variant="smallRegular"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          *
        </Typography>
      )}
    </Typography>
  )
}

export function FieldSubtitle({ content }: { content: string }) {
  if (!content) {
    return null
  }

  return (
    <Typography
      variant="body"
      fontSize={'small'}
      sx={(theme) => ({
        color: theme.palette.text.primary,
      })}
    >
      {content}
    </Typography>
  )
}

export const UploadButton = styled(Button)({
  padding: '16px',
  height: '28px',
  width: 'fit-content',
})

export const StyledImagePlaceholder = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'cover',
  borderRadius: '4px',
})

export const StyledIconContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.tiptip[500],
  borderRadius: '4px',
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledTextAreaCount = styled(Typography)(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    ...theme.typography.smallRegular,
    color: theme.palette.text.tertiery,
    backgroundColor: theme.palette.background.tertiery,
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    padding: '8px 12px',
    margin: '0px !important',
  }),
)
