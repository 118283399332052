import { useMediaQuery, useTheme } from '@mui/material'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import {
  CoinIconContainer,
  CoinInfoContainer,
  CoinPriceContainer,
  CoinPriceLabel,
  CoinValueLabel,
  PriceCurrency,
  StyledCoin,
  StyledDivider,
  TotalPaymentLabel,
} from '../DirectPaymentDrawerContent/styled'
import {
  AdditionalFeeContainer,
  AdditionalFeeLabel,
  AdditionalFeeLabelContainer,
  AdditionalFeeValueContainer,
} from './styled'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import ActionButton from 'components/commons/Button/Action'
import Box from 'components/commons/Box'
import { IPayment } from 'stores/domains/TransactionHistory/Transaction/interface'
import { useTranslation } from 'react-i18next'

interface IAdditionalFee {
  code: string
  name: string
  note: string
  value: number
}
export interface IPaymentMethod {
  admin_fee_title: string
  black_icon: string
  code: string
  coin: {
    balance: number
    rate: number
    sub_total: number
    total_additional_fee: number
    total_price: number
    voucher_discount: number
  }
  detail_url: string
  disable_voucher_reason: string
  icon: string
  info: string
  name: string
  payment_channel: string
  price: {
    sub_total: number
    total_additional_fee: number
    total_price: number
    total_price_before_discount: number
    voucher_discount: number
    additional_fee: Array<IAdditionalFee>
  }
  voucher_code: string
  voucher_info: string
}

interface IFeesPaymentDetails {
  paymentDetails?: IPayment
  paymentMethod?: IPaymentMethod
  isAdditionalFeesOpen: boolean
  setIsAdditionalFeesOpen: Function
}
export default function FeesPaymentDetail({
  paymentDetails,
  paymentMethod,
  isAdditionalFeesOpen,
  setIsAdditionalFeesOpen,
}: IFeesPaymentDetails) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )

  const isTiptipCoin = () => {
    if (paymentDetails) {
      return paymentDetails.payment_method_code !== 'TIPTIP_COIN'
    } else {
      return paymentMethod.code !== 'TIPTIP_COIN'
    }
  }

  const TotalPaymentContent = () => {
    if (isTiptipCoin()) {
      return (
        <CoinPriceContainer
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          style={{
            display: isDesktopResolution ? 'flex' : 'block',
          }}
        >
          <CoinValueLabel>
            <PriceCurrency>Rp</PriceCurrency>
            {paymentMethod &&
              NumberBaseFormatter(
                paymentMethod?.price?.total_additional_fee,
              )}
            {paymentDetails &&
              NumberBaseFormatter(
                paymentDetails?.total_additional_fee,
              )}
          </CoinValueLabel>
        </CoinPriceContainer>
      )
    } else {
      return (
        <CoinPriceContainer
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          style={{
            display: isDesktopResolution ? 'flex' : 'block',
          }}
        >
          <Box style={{ display: 'block' }}>
            <CoinValueLabel>
              <PriceCurrency>Rp</PriceCurrency>
              {paymentMethod &&
                NumberBaseFormatter(
                  paymentMethod?.price?.total_additional_fee,
                )}
              {paymentDetails &&
                NumberBaseFormatter(
                  paymentDetails?.total_additional_fee,
                )}
            </CoinValueLabel>
            <CoinValueLabel>
              (
              <PriceCurrency>
                <StyledCoin />
              </PriceCurrency>
              {paymentDetails &&
                NumberBaseFormatter(
                  paymentDetails.coin.total_additional_fee,
                )}
              {paymentMethod &&
                NumberBaseFormatter(
                  paymentMethod.coin.total_additional_fee,
                )}
              )
            </CoinValueLabel>
          </Box>
        </CoinPriceContainer>
      )
    }
  }
  return (
    <ModalDrawer
      title={t('paymentDetailWidgetAdditionalFeeLabel')}
      isOpen={isAdditionalFeesOpen}
      onClose={() => {
        setIsAdditionalFeesOpen(false)
      }}
      sxDialogContainer={{
        maxHeight:
          typeof window !== 'undefined' ? window?.innerHeight : 1080,
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ padding: '16px 0px' }}
    >
      <Box style={{ margin: '16px 0px' }}>
        {(
          paymentMethod?.price?.additional_fee ||
          paymentDetails?.additional_fee
        ).map((additionalFee, index) => {
          return (
            <AdditionalFeeContainer
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              key={index}
            >
              <AdditionalFeeLabelContainer
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <AdditionalFeeLabel>
                  {additionalFee.name}
                </AdditionalFeeLabel>
              </AdditionalFeeLabelContainer>
              <AdditionalFeeValueContainer
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <CoinPriceLabel>
                  <PriceCurrency>Rp</PriceCurrency>
                  {NumberBaseFormatter(additionalFee.value)}
                </CoinPriceLabel>
              </AdditionalFeeValueContainer>
            </AdditionalFeeContainer>
          )
        })}
        <AdditionalFeeLabel
          style={{
            color: theme.palette.text.secondary,
            ...theme.typography.smallRegular,
          }}
        >
          {paymentMethod?.price?.additional_fee[0].note ||
            paymentDetails?.additional_fee[0].note}
        </AdditionalFeeLabel>
        <StyledDivider style={{ margin: '8px 0px' }} />
        <CoinInfoContainer
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <CoinIconContainer style={{ margin: 'auto 0px' }}>
            <TotalPaymentLabel>
              {t('paymentDetailWidgetTotalAdditionalFeeLabel')}
            </TotalPaymentLabel>
          </CoinIconContainer>
          {TotalPaymentContent()}
        </CoinInfoContainer>
        <ActionButton
          sx={{
            padding: '12px 24px',
            width: '100%',
            marginTop: '28px',
          }}
          onClick={() => {
            setIsAdditionalFeesOpen(false)
          }}
        >
          {t('back')}
        </ActionButton>
      </Box>
    </ModalDrawer>
  )
}
